import React from 'react'
import { graphql } from 'gatsby'
import Banner from '../components/Banner/Banner'
import Layout from '../components/Layout/Layout'
import facebookIcon from '../images/icon-facebook.svg';
import instagramIcon from '../images/icon-ig.svg';

export const errorPage = graphql`
  query ContactPageQuery {
    sanityPage(slug: {current: {eq: "contact-us"}}) {
      pageBanner {
        title
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
	  }
  }
`

const ContactUs = ({data}) => {
    return (
        <Layout>
            <div>
                <Banner 
                    heading={ data.sanityPage.pageBanner.title }
                    image={ data.sanityPage.pageBanner.image.asset.fluid }
                    bannerAlt="banner--sm"
                />
                <div className="contact">
                  <div className="container">
                      <div className="contact__cols">
                          <div className="contact__copy">
                              <h2 className="contact__heading">
                                  Contact Us
                                </h2>
                                <p>
                                    If you have any questions, please fill out the form.
                                </p>
                                <h3 className="contact__subheading">
                                    Need help?
                                </h3>
                                <ul className="contact__controls">
                                    <li>
                                        <strong>Email: </strong>
                                        <a href="mailto:drinkfreedomwine@gmail.com">
                                            drinkfreedomwine@gmail.com
                                        </a>
                                    </li>
                                    <li>
                                        <strong>Call:</strong> <a href="tel:8186742005">(818) 674-2005</a>
                                    </li>
                                </ul>
                                <h3 className="contact__subheading">
                                    Follow Us
                                </h3>
                                <div className="contact__social">
                                  <ul className="social social--dark">
                                      <li>
                                        <a href="https://www.facebook.com/FreedomWineCompany/" className="social__icon" target="_blank" rel="noopener noreferrer">
                                            <img src={facebookIcon} alt="facebook icon" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.instagram.com/freedomwine/" className="social__icon"  target="_blank" rel="noopener noreferrer">
                                            <img src={instagramIcon} alt="facebook icon" />
                                        </a>
                                      </li>
                                  </ul>
                                </div>
                          </div>
                          <div className="contact__form">
                            <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                                  <input type="hidden" name="bot-field" />
                                  <input type="hidden" name="form-name" value="contact" />
                                  <input type="text" name="name" id="name" placeholder="Name" />
                                  <input type="email" name="email" id="email" placeholder="Email address" />
                                  <textarea name="message" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                                  <input type="submit" value="Send Message" className="special cta" />
                                  <input type="hidden" name="subject" value="Freedom Wine Website Inquiry" />
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        </Layout>
    )
}

export default ContactUs;
